// Libraries
import _ from 'lodash';
import moment, {Moment, unitOfTime} from 'moment-timezone';

import Timezone from '@shared/modules/Organization/enums/Timezone';

type DateOrMoment = Date | Moment;

export type MomentType = Moment;

// TODO(jay): This refers to itself and prevents TS from getting a solid typing
// Need to type all responses to fix that, but everything inputting moment is also a problem--will touch up later
// The type was `any` before and still is now, so not losing any typing
const Datetime: any = {
  DISPLAY_SHORT_DATE: 'MM/DD/YY',
  DISPLAY_DATE: 'MMMM Do, YYYY',
  DISPLAY_FULL_DATE: 'dddd, MMMM Do, YYYY',
  DISPLAY_DATETIME: 'MM/DD/YY h:mm A',
  DISPLAY_MONTH_DAY: 'MM/DD',
  DISPLAY_MONTH_DAY_TIME: 'MM/DD, h:mm A',
  DISPLAY_TIME: 'h:mm A',

  SERVER_DATE: 'YYYY-MM-DD',

  FORM_TIME: 'hh:mm A',
  SERVER_TIME: 'HHmm',

  SORT_DATETIME: 'YYYY-MM-DD HHmm',

  clone: (datetime: Moment) => moment(datetime),

  // Turn the server data into datetime objects.
  fromDate: (string: string, format = 'YYYY-MM-DD') => moment(string, format),
  fromDatetime: (string: string, isSkipFormat?: boolean) => {
    // TODO (warren): we add a 'Z' if one is not present
    // because our server sometimes returns datetime strings
    // without 'Z's. If we can fix the server to always
    // return datetime strings with 'Z's, we won't need this hack.
    if (!isSkipFormat && string[string.length - 1] !== 'Z') {
      string += 'Z';
    }
    return moment(string, moment.ISO_8601);
  },
  fromDatetimeToUtc: (string: string, isSkipFormat?: boolean) => {
    // HACK(dan): See fromDatetime ^
    if (!isSkipFormat && string[string.length - 1] !== 'Z') {
      string += 'Z';
    }
    return moment.utc(string, moment.ISO_8601);
  },
  // Given a server date and a server time (ex. '2019-09-25' and '0900'),
  // create an appropriate moment datetime object.
  fromDateAndTime: (dateString: string, timeString: string) => {
    return moment(`${dateString} ${timeString}`, `${Datetime.SERVER_DATE} ${Datetime.SERVER_TIME}`);
  },
  fromTime: (string: string, format = Datetime.SERVER_TIME) => moment(string, format),

  // Turn the datetime objects into display values.
  toDisplayDate: (datetime: Moment, format = Datetime.DISPLAY_FULL_DATE) => datetime.format(format),
  toDisplayDatetime: (datetime: Moment, format = Datetime.DISPLAY_DATETIME) =>
    datetime.format(format),

  // eslint-disable-next-line
  toDisplayTime: (datetime: Moment, format = Datetime.DISPLAY_TIME, timezone: string) =>
    timezone ? datetime.tz(timezone).format(format) : datetime.format(format),

  toDisplayDateWithWeekdayName: (datetime: Date) => {
    return datetime.toLocaleDateString('en-us', {
      weekday: 'short',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
    });
  },
  toDisplayDateWithWeekdayNameAndYear: (datetime: Date) => {
    return datetime.toLocaleDateString('en-us', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC',
    });
  },

  // Convert the datetime objects into server strings.
  toDate: (datetime: Moment, format = Datetime.SERVER_DATE) => datetime.format(format),
  toTime: (datetime: Moment, format = Datetime.SERVER_TIME) => datetime.format(format),

  // Converts server data into display data directly.
  convertToDisplayDate: (string: string, format: string) =>
    Datetime.toDisplayDate(Datetime.fromDate(string), format),
  convertToDisplayDatetime: (string: string, format: string) => {
    return Datetime.toDisplayDatetime(Datetime.fromDatetime(string), format);
  },
  convertToDisplayTime: (string: string, format: string) =>
    Datetime.toDisplayTime(Datetime.fromTime(string, format)),

  // Converts date objects to server data. This is for the DateInput and TimeInput components.
  convertToDate: (date: DateOrMoment, format: string) => Datetime.toDate(moment(date), format),
  convertToTime: (time: string) => Datetime.toTime(Datetime.fromTime(time, Datetime.FORM_TIME)),

  // Converts to our specific form date and time values for DateInput and TimeInput.
  // TODO(mark): react-datepicker 1.8.0 needs moment date.
  toFormDate: (date: string) => (date ? Datetime.fromDate(date) : ''),
  toFormTime: (time: string) =>
    time ? Datetime.toTime(Datetime.fromTime(time), Datetime.FORM_TIME) : '',

  // Convert to our specific mutation date and time values.
  toMutationDate: (date: Date) => date && Datetime.convertToDate(date),
  toMutationTime: (time: string) => time && Datetime.convertToTime(time),
  toMutationMonth: (date: Date) => date && Datetime.convertToDate(date, 'YYYY-MM'),

  // Converts a moment datetime to an ISO string for the server.
  toTimestamp: (datetime: Moment) => datetime.utc().toISOString(),

  // Getters / Accessors
  isFuture: (date: DateOrMoment) => moment(date).isAfter(Datetime.today),
  isPast: (date: DateOrMoment) => moment(date).isBefore(Datetime.yesterday),
  isToday: (date: DateOrMoment) => moment(date).endOf('day').isSame(Datetime.today),
  isYesterday: (date: DateOrMoment) => moment(date).endOf('day').isSame(Datetime.yesterday),
  isSameDay: (dateOne: DateOrMoment, dateTwo: DateOrMoment) =>
    moment(dateOne).endOf('day').isSame(moment(dateTwo).endOf('day')),
  isBetweenTime: (time: string, timeStart: string, timeEnd: string) => {
    return (
      Datetime.fromTime(time) >= Datetime.fromTime(timeStart) &&
      Datetime.fromTime(time) < Datetime.fromTime(timeEnd)
    );
  },
  isValidDateString: (dateString: string, format = Datetime.SERVER_DATE) =>
    moment(dateString, format, true).isValid(),

  // Get Day with Ordinal
  getOrdinalDay: (string: string, format = 'D') => moment(string, format).format('Do'),

  // Get Day with Ordinal for a full month
  // TODO(jay): Check if this type is right, but `.moth(0).date` only takes in a number
  getOrdinalForEntireMonth: (string: number) => moment().month(0).date(string).format('Do'),

  // If datetime is a Sunday, it will return the same date.
  previousSunday: (datetime: Moment) => datetime.startOf('week'),
  previousMonday: (datetime: Moment) => {
    const isSunday = datetime.day() === 0;
    const previousSunday = isSunday
      ? Datetime.previousSunday(datetime.subtract(1, 'day'))
      : Datetime.previousSunday(datetime);
    return previousSunday.add(1, 'day');
  },

  previousDay: (date: DateOrMoment) => Datetime.toDate(moment(date).subtract(1, 'day')),
  previousMonth: (date: DateOrMoment) => Datetime.toDate(moment(date).subtract(1, 'month')),

  nextSaturday: (datetime: Moment) => datetime.startOf('week').add(6, 'day'),
  nextSunday: (datetime: Moment) => datetime.startOf('week').add(7, 'day'),

  nextDay: (date: DateOrMoment) => Datetime.toDate(moment(date).add(1, 'day')),
  nextMonth: (date: DateOrMoment) => Datetime.toDate(moment(date).add(1, 'month')),

  // Add days to date
  addDaysToDate: (date: DateOrMoment, days: number) =>
    Datetime.toDate(moment(date).add(days, 'day')),

  // Convert String display to date time object
  convertDisplayDateToDate: (string: string, format: string) => moment(string, format),

  nextOccurenceOfDayOfWeek: (targetDayOfWeek: number) => {
    const today = moment().isoWeekday();
    if (today < targetDayOfWeek) {
      // If the target day of week has not passed then just return this week's instance of that day
      return moment().isoWeekday(targetDayOfWeek);
    } else {
      // Otherwise, return next week's instance of the day
      return moment().add(1, 'weeks').isoWeekday(targetDayOfWeek);
    }
  },

  nextOccurenceOfDayOfMonth: (targetDayOfMonth: number, startDate: DateOrMoment) => {
    let startDay = moment().date();

    // Adding new logic to set if the last day of the month is invalid IE: 6/31
    // In Moment, if the date is invalid, it automatically sets it to the next valid date
    // but instead of doing that, we want to set it to the end date of the month,
    // we can achive this by taking the minimum date between the end of month date and the target day
    const lastDayOfMonth = moment().endOf('month').date();
    const newTargetDayOfMonth = Math.min(targetDayOfMonth, lastDayOfMonth);

    if (startDate) {
      startDay = moment(startDate).date();
    }
    if (startDay < newTargetDayOfMonth) {
      return moment().date(newTargetDayOfMonth);
    } else {
      if (startDate) {
        return moment(startDate).add(1, 'months').date(targetDayOfMonth);
      }
      return moment().add(1, 'months').date(newTargetDayOfMonth);
    }
  },

  // isShortened removes the word 'ago' from the label
  timeAgo: (date: string, isShortened?: boolean) =>
    moment(Datetime.fromDatetime(date)).fromNow(isShortened),

  getDateRange: (datetime: DateOrMoment, plusDays: number) => {
    return _.range(plusDays).map((offset) => {
      return moment(datetime).add(offset, 'day');
    });
  },

  getNumberOfDays: (startDate: DateOrMoment, endDate: DateOrMoment) => {
    const start = moment(startDate, 'YYYY-MM-DD');
    const end = moment(endDate, 'YYYY-MM-DD');
    // We add one to return the total number of days rather
    // than the difference between between two dates.
    // Eg. startDate of 3/1 and endDate of 3/2 returns 2.
    return moment.duration(end.diff(start)).asDays() + 1;
  },

  getElapsedSeconds: (startDatetime: Moment, endDatetime = moment()) => {
    const duration = moment.duration(endDatetime.diff(startDatetime));
    return duration.asSeconds();
  },

  getDayCounterText: ({
    startDate,
    endDate,
    currentDate,
  }: {
    startDate: DateOrMoment;
    endDate: DateOrMoment;
    currentDate: DateOrMoment;
  }) => {
    const totalDays = Math.round(Datetime.getNumberOfDays(startDate, endDate));
    const currentDay = Math.round(Datetime.getNumberOfDays(startDate, currentDate));
    return `${currentDay} of ${totalDays}`;
  },

  getIsDateInsideOfDateRange: ({
    date,
    startDate,
    endDate,
  }: {
    date: string;
    startDate: string;
    endDate: string;
  }) => {
    const dateShortDate = date && Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE);
    const startShortDate =
      startDate && Datetime.convertToDisplayDate(startDate, Datetime.DISPLAY_SHORT_DATE);
    const endShortDate =
      endDate && Datetime.convertToDisplayDate(endDate, Datetime.DISPLAY_SHORT_DATE);

    if (dateShortDate && startShortDate && endShortDate) {
      if (dateShortDate >= startShortDate && dateShortDate <= endShortDate) {
        return true;
      }
    }
    return false;
  },
  getFriendlyHourString: (hour: number, timezone: string, shouldShowTimezoneString?: boolean) => {
    const isDST = moment().isDST();
    const utcOffset = Timezone.getTimezoneUTCOffset(timezone, isDST);
    const momentUtc = moment.utc().startOf('day').hour(hour);
    const momentLocal = momentUtc.utcOffset(utcOffset);
    return `${momentLocal.format('h:mm A')}${
      shouldShowTimezoneString ? ` ${timezone}${isDST ? ' DST' : ''}` : ''
    }`;
  },

  getHourDropdownOptions: (timezone: string) => {
    return Array.from({length: 24}, (_, i) => i).map((hour) => ({
      label: Datetime.getFriendlyHourString(hour, timezone, true),
      value: hour,
    }));
  },

  getDifference: (datetime1: Moment, datetime2: Moment, unit: unitOfTime.Diff = 'minutes') => {
    return datetime2.diff(datetime1, unit);
  },

  // Initializes a Datetime object from a date.
  new: (date: Date) => moment(date),

  get now() {
    return moment();
  },
  get startOfMonth() {
    return Datetime.toDate(moment().startOf('month'));
  },
  get endOfMonth() {
    return Datetime.toDate(moment().endOf('month'));
  },
  get startOfWeek() {
    return Datetime.toDate(moment().startOf('isoWeek'));
  },
  get endOfWeek() {
    return Datetime.toDate(moment().endOf('isoWeek'));
  },
  get today() {
    return Datetime.now.endOf('day');
  },
  get tomorrow() {
    return Datetime.today.add(1, 'days');
  },
  get yesterday() {
    return Datetime.today.add(-1, 'days');
  },
  get currentTime() {
    return Datetime.now.format('hh:mm A');
  },
};

export default Datetime;
