// Libraries
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {ViewStyleProp} from '@supermove/styles/types';

import Styled from '../Styled';

import DragAndDropListV1 from './DragAndDropListV1';

const useDragAndDrop = ({
  onReorder,
  children,
}: {
  onReorder: (params: {fromIndex: number; toIndex: number}) => void;
  children: React.ReactElement[];
}) => {
  const [isDraggingId, setIsDraggingId] = useState<number | string>();
  const childrenWithIds = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      id: child.key,
    });
  });

  const itemIds = React.Children.map(childrenWithIds, (child: {props: {id: string}}) => {
    return child.props.id;
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8, // 8px movement required before activation
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250, // Wait for 250ms before activation
        tolerance: 5, // Small tolerance for slight movement
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = ({active, over}: DragEndEvent) => {
    const fromIndex = active.data.current?.sortable.index;
    const toIndex = over?.data.current?.sortable.index;

    if (!_.isNil(toIndex) && fromIndex !== toIndex) {
      onReorder({fromIndex, toIndex});
    }
    setIsDraggingId(undefined);
  };

  return {
    childrenWithIds,
    itemIds,
    isDragging: !_.isNil(isDraggingId),
    isDraggingId,
    setIsDraggingId,
    handleDragEnd,
    sensors,
  };
};

const DroppableContainer = Styled.View`
`;

const ItemContainer = Styled.View<{index: number; spaceBetweenItems: number}>`
  flex-direction: row;
  align-items: center;
  flex: 1;
  z-index: ${({index}) => 5000 - index};
  margin-vertical: ${({spaceBetweenItems}) => spaceBetweenItems / 2}px;
`;

const SortableItem = ({
  id,
  index,
  spaceBetweenItems,
  isDragging,
  isDraggingOtherItem,
  isDragHidden,
  isDragDisabled,
  itemContainerStyle,
  children,
}: {
  id: string;
  index: number;
  spaceBetweenItems: number;
  isDragging: boolean;
  isDraggingOtherItem: boolean;
  isDragHidden: boolean;
  isDragDisabled: boolean;
  itemContainerStyle?: ViewStyleProp;
  children: React.ReactNode;
}) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id,
    disabled: isDragDisabled,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition: isDragging ? transition : 'none',
        zIndex: isDraggingOtherItem ? 'auto' : 999,
        opacity: isDraggingOtherItem ? 0.5 : 1,
        position: isDragging ? 'relative' : 'static',
      }}
    >
      <ItemContainer index={index} spaceBetweenItems={spaceBetweenItems} style={itemContainerStyle}>
        {!isDragHidden && (
          <React.Fragment>
            <div
              {...attributes}
              {...listeners}
              style={{
                cursor: 'grab',
                touchAction: 'none',
                userSelect: 'none',
                WebkitUserSelect: 'none',
              }}
            >
              <Icon
                source={Icon.GripVertical}
                color={isDragDisabled ? colors.gray.tertiary : colors.gray.secondary}
                size={Icon.Sizes.Large}
              />
            </div>
            <Space width={10} />
          </React.Fragment>
        )}
        {children}
      </ItemContainer>
    </div>
  );
};

const DragAndDropListV2 = ({
  scrollRef,
  spaceBetweenItems = 0,
  onReorder = () => {},
  isDisabled = false,
  isDisabledWithVisibleIcons = false,
  disabledIndexes = [],
  itemContainerStyle,
  children,
}: {
  scrollRef?: React.RefObject<HTMLDivElement>;
  spaceBetweenItems?: number;
  onReorder?: (params: {fromIndex: number; toIndex: number}) => void;
  isDisabled?: boolean;
  isDisabledWithVisibleIcons?: boolean;
  disabledIndexes?: number[];
  itemContainerStyle?: ViewStyleProp;
  children: React.ReactElement[];
}) => {
  const {
    childrenWithIds,
    itemIds,
    isDragging,
    isDraggingId,
    setIsDraggingId,
    handleDragEnd,
    sensors,
  } = useDragAndDrop({onReorder, children});

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragPending={({id}) => setIsDraggingId(id)}
      onDragAbort={() => setIsDraggingId(undefined)}
      onDragEnd={handleDragEnd}
      autoScroll={{enabled: false}}
    >
      <div
        ref={scrollRef}
        style={{
          flexDirection: 'column',
          overflowY: 'visible',
        }}
      >
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          {React.Children.map(childrenWithIds, (item, index) => {
            const itemId = item.props.id;
            const isDraggingOtherItem = isDragging && isDraggingId !== itemId;
            const isDisabledIndex = disabledIndexes.includes(index);

            return (
              <SortableItem
                key={item.key}
                id={itemId}
                index={index}
                isDragging={isDragging}
                isDraggingOtherItem={isDraggingOtherItem}
                isDragHidden={isDisabled}
                isDragDisabled={isDisabledIndex || isDisabledWithVisibleIcons}
                spaceBetweenItems={spaceBetweenItems}
                itemContainerStyle={itemContainerStyle}
              >
                {item}
              </SortableItem>
            );
          })}
        </SortableContext>
      </div>
    </DndContext>
  );
};

const DragAndDropList = ({
  scrollRef,
  indexOfEdit,
  spaceBetweenItems = 0,
  onReorder = () => {},
  isReordering = false,
  isDisabled = false,
  isDisabledWithVisibleIcons = false,
  disabledIndexes = [],
  droppableStyle,
  itemContainerStyle,
  children,
  isDragAndDropListV1 = false,
}: {
  scrollRef?: React.RefObject<HTMLDivElement>;
  indexOfEdit?: number | null;
  spaceBetweenItems?: number;
  onReorder?: (params: {fromIndex: number; toIndex: number}) => void;
  isReordering?: boolean;
  isDisabled?: boolean;
  isDisabledWithVisibleIcons?: boolean;
  disabledIndexes?: number[];
  droppableStyle?: ViewStyleProp;
  itemContainerStyle?: ViewStyleProp;
  children: React.ReactElement[];
  isDragAndDropListV1?: boolean;
}) => {
  if (isDragAndDropListV1) {
    return (
      <DragAndDropListV1
        scrollRef={scrollRef}
        indexOfEdit={indexOfEdit}
        spaceBetweenItems={spaceBetweenItems}
        onReorder={onReorder}
        isReordering={isReordering}
        isDisabled={isDisabled}
        isDisabledWithVisibleIcons={isDisabledWithVisibleIcons}
        disabledIndexes={disabledIndexes}
        // @ts-expect-error using ViewStyleProp but expecting CSSProperties
        droppableStyle={droppableStyle}
        // @ts-expect-error using ViewStyleProp but expecting CSSProperties
        itemContainerStyle={itemContainerStyle}
      >
        {children}
      </DragAndDropListV1>
    );
  }
  return (
    <DragAndDropListV2
      scrollRef={scrollRef}
      spaceBetweenItems={spaceBetweenItems}
      onReorder={onReorder}
      isDisabled={isDisabled}
      isDisabledWithVisibleIcons={isDisabledWithVisibleIcons}
      disabledIndexes={disabledIndexes}
      itemContainerStyle={itemContainerStyle}
    >
      {children}
    </DragAndDropListV2>
  );
};

export default DragAndDropList;
